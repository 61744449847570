import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";

import Button from "@ordermygear/button";
import { H2 } from "@ordermygear/typography";

import { User, Lock } from "./Icons";
import { LoginTemplate } from "./LoginTemplate";
import GoogleSignIn from "./GoogleSignIn";

import {
  ForgotPasswordText,
  FormArea,
  FullWidthInput,
  PrivacyText,
  SuccessMessage,
} from "./StyledComponents";
import Cookie from "js-cookie";

interface Props {
  csrfToken: string;
  googleClientId: string;
  errorMsg: string | string[];
}

type PropsType = Props & RouteComponentProps<{}>;

export default class Login extends React.Component<PropsType, Props> {
  constructor(props: PropsType) {
    super(props);

    let errMsg: string[] = (
      typeof props.errorMsg === "string" || props.errorMsg instanceof String
        ? [props.errorMsg]
        : props.errorMsg
    ) as string[];
    const auth0cookie = Cookie.get("auth0user");
    if (auth0cookie) {
      window.location.replace(auth0cookie);
    }
    this.state = {
      csrfToken: props.csrfToken,
      googleClientId: props.googleClientId,
      errorMsg: errMsg,
    };
  }

  googleSignIn(res: any) {
    if (!res.clientId || !res.credential) return;
    (
      document.querySelector(
        "input[name=google_signin_id_token]"
      ) as HTMLInputElement
    ).value = res.credential;
    (
      document.querySelector("form#login-box-inner") as HTMLFormElement
    ).submit();
  }

  render() {
    const success = this.props.location.search === "?success";

    return (
      <LoginTemplate>
        <form
          id="login-box-inner"
          action={"/login" + this.props.location.search}
          method="post"
        >
          <H2 weight="regular">Log In</H2>
          {success ? (
            <SuccessMessage>
              Success! Password recovery instructions have been sent to the
              email you provided.
            </SuccessMessage>
          ) : null}
          <FormArea>
            <FullWidthInput
              adornmentLeft={<User />}
              size="large"
              placeholder="Email"
              type="text"
              name="username"
              tabIndex={1}
              error={this.state.errorMsg.length ? " " : undefined}
            />
            <FullWidthInput
              adornmentLeft={<Lock />}
              size="large"
              placeholder="Password"
              name="password"
              type="password"
              tabIndex={2}
              error={this.state.errorMsg[0]}
            />
          </FormArea>
          <FormArea>
            <Button
              color="primary"
              display="block"
              type="submit"
              value="Log In"
            >
              Log In
            </Button>
            <Link to="/forgot" href="/forgot">
              <ForgotPasswordText>Forgot Password?</ForgotPasswordText>
            </Link>
          </FormArea>
          <GoogleSignIn
            googleClientId={this.props.googleClientId}
            signInCallback={this.googleSignIn}
          />
          <input
            type="hidden"
            name="_csrf"
            value={this.props.csrfToken}
            readOnly
          />
          <input type="hidden" name="google_signin_id_token" value="" />
          <PrivacyText>
            By clicking Log in, you agree to OrderMyGear's{" "}
            <a
              href="https://www.ordermygear.com/privacy.html"
              target="_blank"
              className="terms-link"
            >
              Privacy Policy
            </a>{" "}
            &{" "}
            <a
              href="https://www.ordermygear.com/terms.html"
              target="_blank"
              className="terms-link"
            >
              Terms
            </a>
            .
          </PrivacyText>
        </form>
      </LoginTemplate>
    );
  }
}
