import React from 'react'
import { Helmet } from 'react-helmet-async'

import Button from '@ordermygear/button'

import { Chevron, Logo } from './Icons'

import {
  AppGridWrap,
  BlogArea,
  BrandArea,
  LinkToOmg,
  LoginArea,
  LogoArea,
  LogoSubheader
} from './StyledComponents'

interface LoginTemplateProps {
  children: React.ReactNode
}

export const LoginTemplate = (props: LoginTemplateProps) => {
  return (
    <>
      <Helmet>
        <title>Log In to OrderMyGear</title>
      </Helmet>
      <AppGridWrap>
        <BrandArea xs="12" sm="12" md="12" lg="7" xl="8">
          <LinkToOmg weight="medium">
            <a href="http://www.ordermygear.com">
            <Chevron />
            <span>Back to OrderMyGear</span>
            </a>
          </LinkToOmg>
          <LogoArea>
            <Logo />
          </LogoArea>
          <BlogArea>
            <LogoSubheader weight="regular">
              Stay up to date with industry trends, new features, and OMG
              resources
            </LogoSubheader>
            <a href="https://blog.ordermygear.com/" >
              <Button type="anchor" color="primary" size="large">
                Visit our blog
              </Button>
            </a>
          </BlogArea>
        </BrandArea>
        <LoginArea xs="12" sm="12" md="12" lg="5" xl="4">
          {props.children}
        </LoginArea>
      </AppGridWrap>
    </>
  )
}
