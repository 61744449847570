import * as React from 'react';
import { Helmet } from 'react-helmet-async';


import '../../css/login.scss';
import LogoImg from '../../images/logo_primary_wide.svg';
import Header from './Header';

interface State {
    errorMsg: string | string[];
    csrfToken: string;
}

interface Props {
  csrfToken: string;
  errorMsg: string | string[];
}

export default class ErrorPage extends React.Component <Props, State> {

  constructor(props: Props) {
    super(props);

    let errMsg: string[] = (typeof(props.errorMsg) === 'string' || props.errorMsg instanceof String ? [props.errorMsg] : props.errorMsg) as string[];

    this.state = {
        csrfToken: props.csrfToken,
        errorMsg: errMsg
    }
  }

  componentDidMount() {
    setTimeout(() => {
      window.location.href = "/";
    }, 3000)
  }

  render () {
    let errors = []
    let i = 0;
    for(let msg of this.state.errorMsg) {
        errors.push(<li id="login-error-msg" key={i++}>{msg}</li>);
    }
    return (<div className="page-container">
      <Helmet>
      <title>Log In Issue</title>
      </Helmet>
      <div className="login-container">
          <div className="login-content">
              <div className="login-logo"><a className="brand" href="/"><img src={LogoImg} width="100%" alt="OrderMyGear"/></a></div>
              <div id="login-error" className="error"><strong>Whoops!</strong>
                <p>An error occurred during the login process. Please try again. You will be automatically redirected in three seconds.</p>
                <ul className="error_list">
                  { errors }
                </ul>
              </div>
          </div>
      </div>
      </div>
    );
  }
};


