import * as Express from 'express';
import * as jwt from 'jsonwebtoken';

import fetch from 'cross-fetch';

import * as constants from '../constants';

const COOKIE_OPTIONS = {
  domain: constants.COOKIE_DOMAIN,
  secure: constants.COOKIE_SECURE,
  signed: false
}

function setAuthCookie(token: string, res: Express.Response) {
  let exp = new Date(new Date().getTime()+2592000000);
  let options = {...COOKIE_OPTIONS,
     httpOnly: true,
     expires: exp,
     maxAge: 2592000000,
   };
  res.cookie(constants.AUTH_COOKIE_NAME, token, options);
}

export default async (req: Express.Request, res: Express.Response) => {
    let redirectURL = req.query.return || constants.DEFAULT_APP_URL;
    if (req.session) req.session.return = redirectURL;

    //TODO: This is a dealer switcher hack
    if((req.session && !req.session.new_tkn) && req.query.new_tkn) {
        req.session.new_tkn = req.query.new_tkn;
    }

    try {
      let cookieSession = req.cookies[constants.AUTH_COOKIE_NAME];
      if(cookieSession) {
        try {
          //@ts-ignore FIXME: Total hack... refactor decodeJWT
          let p = new Promise((resolve, reject) => {
          jwt.verify(cookieSession, constants.JWT_PUBLIC_KEY || '', constants.JWT_OPTIONS, (err, data) => {
              if(err) {
                reject(err);
              } else {
                resolve(data);
              }
            });
          });
          let decoded = (await p as any);
          if(decoded) {
            let jwtUrl = constants.USER_SERVICE_BASE + '/jwts/';
            let jsonHeaders = {'Content-Type': 'application/json',
                               'Accept': 'application/json',
                               };
            // refresh a signed JWT session from the user-service
            let jwtResp = await fetch(jwtUrl, {
              method: 'post',
              body: JSON.stringify({'jwts': [{claims: decoded.data}]}),
              headers: jsonHeaders,
            })

            if(jwtResp.status != 201) {
              let errBody = await jwtResp.json();
              let errors = "";
              for(let e of errBody.errors) {
                errors += e.detail + "\r\n";
              }
              throw new Error("Failed to get jwt: " +  errors);
            }

            let jwtBody = await jwtResp.json();
            setAuthCookie(jwtBody.jwts[0].token, res);

            return res.redirect(redirectURL);

          } else {
              throw new Error('Invalid or missing JWT');
          }
        } catch (e) {
          req.log.error(e);
          return res.redirect('/logout?error');
        }
      }
    } catch(e) {
      req.log.error(e);
      return res.redirect('/');
    }
}
