import styled from '@emotion/styled';
import { GridWrap, GridSpan } from '@ordermygear/grid';
import { OMGTheme } from '@ordermygear/theme';
import { H3, H2, Text } from '@ordermygear/typography';
import Input from '@ordermygear/input';
import mastheadUrl from '../../images/loginBG.jpg';

export const AppGridWrap = styled(GridWrap)`
  background: url('${mastheadUrl}') no-repeat top center;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  border: none;
  @media (min-width: 1680px) {
    margin: 0;
    max-width: unset;
  }

  @media (max-width: ${OMGTheme.breakpoints.md}) {
    grid-template-rows: 2fr 3fr;
  }
`

export const BackText = styled('div')`
  align-items: center;
  color: #807f7f;
  display: flex;
  font-size: 20px;
  justify-content: center;
  line-height: 27px;

  span {
    display: flex;
    padding-right: 8px;
  }
`

export const BlogArea = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  margin-bottom: auto;
  @media (max-width: ${OMGTheme.breakpoints.lg}) {
    display: none;
  }
`

export const BrandArea = styled(GridSpan)`
  margin-right: 0;
  margin-left: 0;
  display: flex;
  flex-direction: column;
`

export const ForgotPasswordText = styled(Text)`
  color: ${OMGTheme.colors.primary};
  margin-bottom: 0;
`

export const ForgotInstructionsText = styled(Text)`
  color: #666666;
  margin-bottom: 0;
`

export const FormArea = styled('div')`
  display: grid;
  grid-gap: 16px;
  justify-items: center;
  @media (max-width: ${OMGTheme.breakpoints.lg}) {
    grid-gap: 16px;
  }
`

export const FullWidthInput = styled(Input)`
  div {
    width: 100%;
  }
`

export const LinkToOmg = styled(H2)`
  margin-top: 60px;
  margin-left: 10px;
  & span {
    padding-left: 10px;
  }
  & a {
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  @media (max-width: ${OMGTheme.breakpoints.lg}) {
    margin-top: 24px;
  }
  @media (max-width: ${OMGTheme.breakpoints.sm}) {
    font-size: 1rem;
  }
  @media (min-width: ${OMGTheme.breakpoints.xl}) {
    margin-left: 10%;
  }
`

export const LoginArea = styled(GridSpan)`
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-right: 0;
  margin-left: 0;
  padding: 50px 0;
  @media (min-width: ${OMGTheme.breakpoints.lg}) {
    padding: 0;
    justify-content: center;
  }

  @media (max-width: ${OMGTheme.breakpoints.sm}) {
    padding: 15px 0 0 0;
  }
  & form {
    display: grid;
    grid-template-columns: 300px;
    justify-content: center;
    align-content: center;
    grid-gap: 32px;
    @media (max-width: ${OMGTheme.breakpoints.lg}) {
      grid-gap: 16px;
    }
  }
`

export const LogoArea = styled('div')`
  align-self: center;
  margin-top: auto;
  width: 572px;
  @media (max-width: ${OMGTheme.breakpoints.lg}) {
    min-width: 467px;
    margin-top: auto;
    margin-bottom: auto;
  }
  @media (max-width: ${OMGTheme.breakpoints.md}) {
    min-width: 320px;
  }
  @media (max-width: ${OMGTheme.breakpoints.sm}) {
    width: 60%;
    min-width: 300px;
  }
`

export const LogoSubheader = styled(H3)`
  color: white;
  opacity: 0.75;
  width: 450px;
  text-align: center;
  margin-bottom: 40px;
`

export const PrivacyText = styled(Text)`
  text-align: center;
  margin-top: 20px;
`

export const SuccessMessage = styled('div')`
  color: ${OMGTheme.colors.primary};
  font-size: 0.875rem;
  line-height: 1.1875rem;
`
