import * as Express from 'express';
import fetch from 'cross-fetch';

import * as constants from '../constants';

const userServiceURL = constants.USER_SERVICE_BASE;

export default async (req: Express.Request) => {
  //@ts-ignore
  let id = req.match.params.id;
  try{

    let resp = await fetch(userServiceURL + '/reset_tokens/' + id);
    if(!resp.ok) {
      let err = await resp.json();
      req.log.error(err);
      return {message: "Invalid reset token: " + err, email: null}
    }

    let body = await resp.json();
    return body.reset_tokens[0];
  } catch (e) {
    console.error(e);
    return {message: "Invalid reset token: " + e, email: null}
  }
}
