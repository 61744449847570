import * as React from 'react';
import {RouteComponentProps} from "react-router";
import { Helmet } from 'react-helmet-async';
import fetch from 'cross-fetch';

import Button from '@ordermygear/button';
import { H2, Text} from '@ordermygear/typography';

import LogoImg from '../../images/logo_primary_wide.svg';
import Header from './Header';
import { Lock } from './Icons';
import { LoginTemplate } from './LoginTemplate';

import {
  FormArea,
  FullWidthInput,
  SuccessMessage
} from './StyledComponents';

interface State {
  message: string;
  csrfToken: string;
  email: string;
  resetId: string;
  expired: boolean;
  failed: boolean;
  password: string;
  password2: string;
}

interface Props {
  csrfToken: string;
  email: string;
  message: string;
  expired: boolean;
}

type PropsType = Props & RouteComponentProps<{}>;

export default class Reset extends React.Component<PropsType, State> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      csrfToken: props.csrfToken,
      resetId: (props.match.params as any).id,
      email: props.email,
      message: props.message || '',
      expired: props.expired || false,
      failed: false,
      password: '',
      password2: ''
    }

    this.onSubmit = this.onSubmit.bind(this);
    this.passwordChanged = this.passwordChanged.bind(this);
  }

  async onSubmit(ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault();

    if (this.state.password != this.state.password2) {
      this.setState({message: "Passwords do not match.", failed: true});
      return false;
    }

    try {
      let csrf = (document.querySelector('input[name=_csrf]') as HTMLInputElement).value;
      let resp = await fetch("/reset/" + this.state.resetId, {
        method: "POST",
        credentials: "same-origin",
        body: JSON.stringify({_csrf: csrf, password: this.state.password, confirmPassword: this.state.password2}),
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrf
        }
      })

      if (!resp.ok) {
        let errBody = await resp.json();
        console.error(errBody);
        this.setState({message: "Failed to reset password. " + errBody.error, failed: true});
      } else {
        let respBody = await resp.json();
        if (!respBody.success) {
          this.setState({message: "Failed to reset password.", failed: true});
        } else {
          this.setState({message: "Success", failed: false});
        }
      }
    } catch(e) {
      console.error(e);
      this.setState({message: "Failed to reset password.", failed: true});
    }

    console.log(this.state);
    return false;
  }

  passwordChanged(ev: React.ChangeEvent<HTMLInputElement>) {
    if (ev.target.id == "signin_password") {
      this.setState({password: (ev.target as HTMLInputElement).value});
    }

    if (ev.target.id == "signin_password2") {
      this.setState({password2: (ev.target as HTMLInputElement).value});
    }
  }

  render () {
    let renderError = (this.state.message && this.state.message.length > 0 && this.state.message !== 'Success')
    || (this.state.failed || this.state.expired || !this.state.resetId);

    return (
      <LoginTemplate>
        <form id="login-box-inner" action={"/reset/" + this.state.resetId} method="post" onSubmit={this.onSubmit}>
          <H2 weight="regular">Forgot Password</H2>

          {this.state.message === 'Success' ? (
            <SuccessMessage>
              Your password has been changed for <strong>{this.state.email}</strong>. You can now <a href="/">login to OrderMyGear</a>.
            </SuccessMessage>
          ): null}

          {renderError ? (
            <div>
              <p style={{'color': 'red'}}>{this.state.message}</p>
              <p className={this.state.expired ? "": "hidden"}>
                The reset request is expired or invalid. If you still need to reset your password, try requesting another <a href="/forgot">reset password link</a>.
              </p>
            </div>
          ): null}

          <div id="reset-form" className={this.state.message !== 'Success' && !this.state.expired ? "" : "hidden"}>
            <FormArea>
              <Text>
                Enter a new password for <strong>{this.state.email}</strong>.
              </Text>

              <FullWidthInput
                adornmentLeft={<Lock />}
                size="large"
                id="signin_password"
                label="Password"
                type="password"
                name="password"
                tabIndex={1}
                placeholder="Password"
                value={this.state.password}
                onChange={this.passwordChanged}
              />

              <FullWidthInput
                adornmentLeft={<Lock />}
                size="large"
                id="signin_password2"
                label="Password"
                type="password"
                name="confirmPassword"
                tabIndex={2}
                placeholder="Confirm Password"
                value={this.state.password2}
                onChange={this.passwordChanged}
              />

              <Button
                color="primary"
                display="block"
                type="submit"
                value="Reset Password"
              >
                Reset Password
              </Button>
            </FormArea>

            <input type="hidden" name="_csrf" value={this.state.csrfToken} readOnly />
          </div>
        </form>
      </LoginTemplate>
    );
  }
};
