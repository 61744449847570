import { rehydrateMarks } from 'react-imported-component';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import importedComponents from './imported'; // eslint-disable-line

import App from './App';

const NODE_ENV = process.env.NODE_ENV;
const SSR = process.env.SSR;
const ROUTE_PREFIX = process.env.ROUTE_PREFIX;

//@ts-ignore
const initialState = window.__PRELOADED_STATE__ || {};
//@ts-ignore
delete window.__PRELOADED_STATE__;

const element = document.getElementById('root');

const app = (
  <HelmetProvider>
    <BrowserRouter basename={ROUTE_PREFIX}>
        <App {...initialState} />
      </BrowserRouter>
    </HelmetProvider>
  );

// In production, we want to hydrate instead of render
// because of the server-rendering
if (NODE_ENV === 'production' || SSR === 'true') {
  // rehydrate the bundle marks
  rehydrateMarks().then(() => {
    ReactDOM.hydrate(app, element);
  });
} else {
  ReactDOM.render(app, element);
}

// Hot reload is that easy with Parcel
//@ts-ignore
if (module.hot) {
  //@ts-ignore
  module.hot.accept();
}
