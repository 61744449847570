/// <reference path="../decorations.d.ts" />

import React from 'react';
import styled from '@emotion/styled';
import { Panel, PanelHeader, PanelBody, PanelTitle } from '@ordermygear/panel';
import { GridWrap, GridSpan, FullToHalf } from '@ordermygear/grid';
import { OmgWhiteLogo } from './Icons';

const Layout = styled.div`
  display: grid;
  grid-template-rows: 64px calc(100vh - 64px - 40px) 40px;
  width: 100vw;
`;

const SimpleNav = styled.div`
  width: 100%;
  background-color: #353a48;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PanelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledBody = styled(PanelBody)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6rem 2rem;
`;

const GridLayout = styled(GridWrap)`
  width: 100vw;
  justify-content: center;
  align-content: center;
`

const GroupSwitcher = (props) => {
  return (
    <Layout>
      <SimpleNav>
        <OmgWhiteLogo />
      </SimpleNav>
      <GridLayout>
        <GridSpan xl="4" lg="4" md="3" />
        <GridSpan xl="4" lg="4" md="6" sm="4" xs="12">
          <PanelContainer>
            <Panel>
              <PanelHeader>
                <PanelTitle>Choose an Account</PanelTitle>
              </PanelHeader>
              <StyledBody>
                <dealer-switcher loginUrl={props.loginUrl}></dealer-switcher>
              </StyledBody>
            </Panel>
          </PanelContainer>
        </GridSpan>
      </GridLayout>
    </Layout>
  );
};

export default GroupSwitcher;
