import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, Redirect } from "react-router-dom";
import fetch from 'cross-fetch';

import { Icon } from "@blueprintjs/core";
import Button from '@ordermygear/button';
import { H2 } from '@ordermygear/typography';

import { User } from './Icons';
import { LoginTemplate } from './LoginTemplate';

import {
  BackText,
  ForgotInstructionsText,
  FormArea,
  FullWidthInput,
} from './StyledComponents';

interface State {
  message: string;
  csrfToken: string;
  email: string;
  failed: boolean;
}

interface Props {
  message: string;
  csrfToken: string;
  email: string;
}

type PropsType = Props & RouteComponentProps<{}>

export default class Forgot extends React.Component <PropsType, State> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      csrfToken: props.csrfToken,
      email: props.email,
      message: props.message,
      failed: false,
    }

    this.onSubmit = this.onSubmit.bind(this);
    this.emailChanged = this.emailChanged.bind(this);
  }

  async onSubmit(ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault();

    const msgError = "We encountered a problem. ";
    const msgEmail = "Please check the email address and retry.";

    try {
      let csrf = (document.querySelector('input[name=_csrf]') as HTMLInputElement).value;
      let resp = await fetch("/forgot", {
        method: "POST",
        credentials: "same-origin",
        body: JSON.stringify({_csrf: csrf, email: this.state.email}),
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrf
        }
      })

      if (!resp.ok) {
        this.setState({message: msgError + msgEmail, failed: true});
      } else {
        let respBody = await resp.json();
        if(!respBody.success) {
          this.setState({message: msgError + "\r\n" + respBody.errors, failed: true});
        } else {
          this.setState({message: "Success", failed: false});
        }
      }
    } catch(e) {
      console.error(e);
      this.setState({message: msgError + msgEmail, failed: true});
    }

    return false;
  }

  emailChanged(ev: React.ChangeEvent<HTMLInputElement>) {
    this.setState({email: ev.target.value});
  }

  render () {
    if (!this.state.failed && this.state.message === "Success") {
      return <Redirect to="/?success" />
    }

    return (
      <LoginTemplate>
        <form id="forgot-box-inner" action="/forgot" method="post" onSubmit={this.onSubmit}>
          <H2 weight="regular">Forgot Password</H2>
          <ForgotInstructionsText>
            Enter your email and we will send you password recovery instructions.
          </ForgotInstructionsText>
          <FormArea>
            <FullWidthInput
              adornmentLeft={<User />}
              size="large"
              id="signin_username"
              error={this.state.failed ? this.state.message: null}
              type="text"
              name="email"
              tabIndex={1}
              placeholder="Email"
              value={this.state.email || ""}
              onChange={this.emailChanged}
            />
          </FormArea>
          <FormArea>
            <Button
              color="primary"
              display="block"
              type="submit"
              value="Reset My Password"
            >
              Send
            </Button>
            <Link to="/">
              <BackText>
                <Icon icon="arrow-left" color="#807F7F" iconSize="20"/> Back
              </BackText>
            </Link>
          </FormArea>
          <input
            type="hidden"
            name="_csrf"
            value={this.props.csrfToken}
            readOnly
          />
        </form>
      </LoginTemplate>
    );
  }
}
