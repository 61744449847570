export const NODE_ENV = process.env.NODE_ENV || 'development'
export const USER_SERVICE_BASE = process.env.USER_SERVICE_BASE;
export const DEFAULT_APP_URL = process.env.DEFAULT_APP_URL || 'http://app.dev.omg.pub';
export const SESSION_COOKIE_NAME = process.env.SESSION_COOKIE_NAME || 'omgsess';
export const COOKIE_DOMAIN = process.env.COOKIE_DOMAIN || '.dev.omg.pub';
export const COOKIE_SECURE = (process.env.COOKIE_SECURE == 'true');
export const AUTH_COOKIE_NAME = process.env.AUTH_COOKIE_NAME || 'omgauth';
export const AUTH0_LD_COOKIE_NAME = 'auth0user';
export const DASHBOARD_LD_COOKIE_NAME = 'dash-redirect'
export const AUTH0_LOGIN_REDIRECT_URL = process.env.AUTH0_LOGIN_URL || '';
export const AUTH0_LOGOUT_REDIRECT_URL = process.env.AUTH0_LOGOUT_URL || '';
export const AUTH0_DOMAIN = process.env.AUTH0_DOMAIN || 'omg-staging.us.auth0.com'
export const LD_SDK_KEY = process.env.LD_SDK_KEY || '';
export const REDIS_HOST = process.env.REDIS_HOST || '';
export const REDIS_PASS = process.env.REDIS_PASS || '';
export const REDIS_PORT = process.env.REDIS_PORT || '';
export const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID || "invalid";
export const LOGIN_URL = process.env.LOGIN_URL || '/';
export const JWT_PUBLIC_KEY = process.env.JWT_PUBLIC_KEY;
export const JWT_OPTIONS = {
    algorithms: ['RS256'],
    ignoreExpiration: true
}
