import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import PageNotFound from '@ordermygear/page-not-found';

import routes from '../../shared/routes';

interface AppProps {
  csrfToken: string,
  googleClientId: string,
  navigationUrl: string,
  loginUrl: string
}

interface AppState {
  csrfToken: string,
  googleClientId: string,
}

class App extends React.Component<AppProps, AppState> {

  constructor(props: AppProps) {
    super(props);
  }

  render() {
    return (
      <Switch>
        {routes.map((route, i) => (
          <Route
            key={route.key || i}
            path={route.path}
            exact={route.exact}
            render={(p) => <route.component {...Object.assign(p, this.props)} />}
          />
        ))}
        <Route status={404} component={PageNotFound} />
      </Switch>
    );
  }
}

export default App;
