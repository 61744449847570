// @ts-nocheck
import * as React from "react";
import { Helmet } from "react-helmet-async";

import fetch from "cross-fetch";

const SCRIPT_ID = "google-client-script";
const SCRIPT_URL = "https://accounts.google.com/gsi/client";

interface State {}

interface Props {
  csrf?: string;
  logoutOnly?: true;
  initCallback?: Function;
  signInCallback?: (user: gapi.auth2.GoogleUser) => any;
  signOutCallback?: Function;
  googleClientId: string;
}

export default class GoogleSignInButton extends React.Component<Props, State> {
  private logoutForm: HTMLFormElement;

  constructor(props: Props) {
    super(props);

    if (typeof window !== "undefined") {
      window.signInCallback = props.signInCallback;
    }
  }

  render() {
    const { csrf, googleClientId } = this.props;
    return (
      <form
        method="POST"
        action="/logout"
        ref={(r) => {
          if (r) this.logoutForm = r;
        }}
      >
        <Helmet>
          <script
            src="https://accounts.google.com/gsi/client"
            async
            defer
          ></script>
        </Helmet>
        <input type="hidden" name="_csrf" value={csrf} />
        <div
          id="g_id_onload"
          data-client_id={googleClientId}
          data-callback="signInCallback"
          data-context="signin"
          data-ux_mode="popup"
          data-auto_prompt="false"
        ></div>
        <div
          class="g_id_signin"
          data-type="standard"
          data-shape="rectangular"
          data-theme="outline"
          data-text="signin_with"
          data-size="large"
          data-logo_alignment="center"
          data-width="300"
          data-type="standard"
        ></div>
      </form>
    );
  }
}
