import importedComponent from 'react-imported-component';

import fetchReset from '../backend/prefetch/reset';
import fetchForgot from '../backend/prefetch/forgot';
import fetchLogin from '../backend/prefetch/login';

const Login = importedComponent(() => import('../frontend/js/components/Login'));
const Forgot = importedComponent(() => import('../frontend/js/components/Forgot'));
const Reset = importedComponent(() => import('../frontend/js/components/Reset'));
const ErrorPage = importedComponent(() => import('../frontend/js/components/Error'));
const GroupSwitcherPage = importedComponent(() => import('../frontend/js/components/GroupSwitcher'));

const routes = [
    { path: "/", exact: true, component: Login, ssrFetch: fetchLogin, key: null, match: null},
    { path: "/forgot", component: Forgot, ssrFetch: fetchForgot, key: null, match: null},
    { path: "/reset/:id?", component: Reset, ssrFetch: fetchReset, key: null, match: null},
    { path: "/error", component: ErrorPage, ssrFetch: null, key: null, match: null},
    { path: "/group_select", component: GroupSwitcherPage, ssrFetch: null, key: null, match: null},
]

export default routes;
